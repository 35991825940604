import { chakra, useMediaQuery } from '@fluidtruck/core';
import { SearchIcon } from '@fluidtruck/icons';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { tz as momentTz } from 'moment-timezone';
import { useTranslation } from 'next-i18next';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ampli } from '@/amplitude';
import { Divider, DTRPController, Form, IconButton } from '@/base-components';
import { Button } from '@/base-components';
import { LocationAutocomplete } from '@/components/LocationAutocomplete';
import {
  useCalculateWeeksToDays,
  useWeekCalculator,
} from '@/features/search/weekCalculator/useWeekCalculator';
import { WeekTextWrapper } from '@/features/search/weekCalculator/WeekCalculator';
import {
  useGetOrgEligibleForMonthReservations,
  useOrganizationContext,
  useResize,
} from '@/hooks';
import { ItemsContext } from '@/lib/context';

import {
  DtrpContainer,
  LocationContainer,
  LocationLabel,
  searchFormStyles,
} from './searchBarStyles';

export const dividerStyles = {
  h: { base: '16px', lg: '64px' },
  borderColor: { base: 'transparent', lg: 'gray.400' },
};

const autocompleteService = { current: null };

type FormShape = {
  pickupDate: any;
  dropoffDate: any;
  pickupTime: any;
  dropoffTime: any;
};

type SearchBarProps = {
  handleSetItems: any;
  showMobileSearch: any;
  setShowMobileSearch: any;
  // deliverySubmitInterupt: any;
};

const SearchBar = ({
  handleSetItems,
  showMobileSearch,
  setShowMobileSearch,
}: SearchBarProps) => {
  const { t } = useTranslation(['common', 'search']);
  const tz = momentTz.guess();

  const { numberOfWeeks } = useWeekCalculator();
  const { calculateWeeksToDaysDate } = useCalculateWeeksToDays();

  const {
    searchInfo,
    updateDetailView,
    updateMapLocation,
    updateSearchInfo,
    updateUserLocation,
    userLocation,
  } = useContext(ItemsContext);

  const {
    context: { org: { organization } = {} },
  } = useOrganizationContext();

  const methods = useForm<FormShape>({
    defaultValues: {
      pickupDate: searchInfo?.start.local(),
      dropoffDate: searchInfo?.end.local(),
      pickupTime: searchInfo?.start.local().hour(),
      dropoffTime: searchInfo?.end.local().hour(),
    },
  });

  const { control, setValue, getValues, watch } = methods;

  const formPickup = watch('pickupDate');
  const formDropoff = watch('dropoffDate');

  const [isSmallerThan30em] = useMediaQuery(['(max-width: 30em)']);

  const isMontlyReservationEnabled = useGetOrgEligibleForMonthReservations({
    // @ts-ignore
    ...organization?.['ach-enabled'],
    ...organization?.['organization-settings'],
  });

  const { isMobile } = useResize();
  const type = searchInfo?.type;
  const [location, setLocation] = useState(userLocation);
  const [fail] = useState(null);

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      // @ts-ignore
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      // @ts-ignore
      autocompleteService.geocoder = new window.google.maps.Geocoder();
    }
  }, []);

  useEffect(() => {
    setLocation(userLocation);
  }, [userLocation]);

  const onSubmit: SubmitHandler<FormShape> = data => {
    const { pickupDate, pickupTime, dropoffDate, dropoffTime } = data;

    // switching locations, reset fleet numbers
    if (location?.address !== userLocation?.address) {
      searchInfo.fleetNumbers = null;
    }

    const _start = pickupDate.hour(pickupTime).startOf('h');
    const _end = dropoffDate
      ? dropoffDate.hour(dropoffTime).startOf('h')
      : _start.clone().add(1, 'd');

    updateSearchInfo({
      ...searchInfo,
      start: _start,
      end: _end,
      type,
    });

    ampli.webSearch({
      vehicleType: type || 'all',
      location: location?.address as string,
      pickUp: moment(pickupDate).format('YYYY-MM-DD HH:mm:ss'),
      dropOff: moment(dropoffDate).format('YYYY-MM-DD HH:mm:ss'),
    });

    updateDetailView({ type: '', detailViewId: null });

    if (isMobile && showMobileSearch) {
      setShowMobileSearch(false);
    }
    if (
      location?.lat !== userLocation?.lat &&
      location?.lng !== userLocation?.lng
    ) {
      updateMapLocation(location);
      updateUserLocation(location);
      return;
    }
    handleSetItems();
  };

  useEffect(() => {
    if (!numberOfWeeks) return;
    const [pickupDate, pickupTime] = getValues(['pickupDate', 'pickupTime']);
    const date = calculateWeeksToDaysDate(
      pickupDate?.clone().set('h', pickupTime).startOf('h')
    );
    setValue('dropoffDate', moment(date), { shouldValidate: true });
    setValue('dropoffTime', pickupTime);
  }, [numberOfWeeks, calculateWeeksToDaysDate]);

  const dtrpMaxHours = useMemo(() => {
    const purchaseOrderEnabled =
      organization?.['organization-settings']?.['book-with-purchase-order'];
    const achEnabled = organization?.['ach-enabled'];
    const paygEnabled =
      organization?.['organization-settings']?.['credit-card-payg-enabled'];

    if (purchaseOrderEnabled || achEnabled || paygEnabled) return 8760;
    return 720;
  }, [
    organization?.['organization-settings']?.['book-with-purchase-order'],
    organization?.['ach-enabled'],
    organization?.['organization-settings']?.['credit-card-payg-enabled'],
  ]);

  const handleSetTimes = () => {
    const [pickupDate, pickupTime, dropoffDate, dropoffTime] = getValues([
      'pickupDate',
      'pickupTime',
      'dropoffDate',
      'dropoffTime',
    ]);

    updateSearchInfo({
      ...searchInfo,
      start: pickupDate.clone().set('h', pickupTime).startOf('hour'),
      end: dropoffDate.clone().set('h', dropoffTime).startOf('hour'),
    });
  };

  useEffect(() => {
    if (!formDropoff || !formPickup) return;
    handleSetTimes();
  }, [formPickup, formDropoff]);

  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      onSubmit(watch());
    } else {
      didMountRef.current = true;
    }
  }, [organization?.id]);

  const formStyles = {
    ...searchFormStyles,
    display: { base: showMobileSearch ? 'block' : 'none', lg: 'flex' },
  };

  return (
    <FormProvider {...methods}>
      <Form sx={formStyles} onSubmit={methods.handleSubmit(onSubmit)}>
        {fail && <Alert severity="error">{fail}</Alert>}
        {isMobile && (
          <Divider
            orientation="horizontal"
            sx={{
              ...dividerStyles,
              borderColor: 'gray.400',
              marginBottom: '8',
              marginLeft: '-20px',
              width: 'calc(100% + 40px)',
            }}
          />
        )}
        <LocationContainer>
          <LocationLabel>{t('search:location')}</LocationLabel>
          <LocationAutocomplete
            setLocationAddress={setLocation}
            location={location}
            testId="input-location"
          />
        </LocationContainer>
        <Divider orientation="vertical" sx={dividerStyles} />
        <chakra.div
          sx={{
            width: ['100%', '100%', '490px'],
            display: 'flex',
            pos: 'relative',
          }}
        >
          <DtrpContainer>
            <DTRPController
              displayTime={true}
              maxHours={dtrpMaxHours}
              control={control}
              labels={{
                start: t('common:pickup'),
                end: t('common:dropOff'),
              }}
              names={{
                startDate: 'pickupDate',
                startTime: 'pickupTime',
                endDate: 'dropoffDate',
                endTime: 'dropoffTime',
              }}
            />
          </DtrpContainer>
          {isMontlyReservationEnabled && (
            <chakra.span
              sx={{
                h: '10px',
                mt: '7px',
                display: 'block',
                width: isSmallerThan30em ? '100%' : 'auto',
                zIndex: 'popover',
              }}
              position={['absolute', 'absolute', 'initial']}
              top={['0', '68px']}
              left={['0', '59px']}
            >
              <WeekTextWrapper
                options={{ offset: [0, 42] }}
                originalDate={formPickup as Date}
                timezone={tz}
              />
            </chakra.span>
          )}
        </chakra.div>
        {isMobile ? (
          <Button
            width="100%"
            leftIcon={<SearchIcon />}
            type="submit"
            mt={8}
            disabled={!formPickup || !formDropoff}
            className="search-execute"
            data-test-id="button-search"
          >
            {t('common:button.search')}
          </Button>
        ) : (
          <IconButton
            icon={<SearchIcon />}
            borderRadius="full"
            type="submit"
            disabled={!formPickup || !formDropoff}
            mt={4}
            className="search-execute"
            data-test-id="button-search"
            aria-label="search"
          />
        )}
      </Form>
    </FormProvider>
  );
};

export default SearchBar;
