import useSWR from 'swr';

const fetcher = (url: string) => fetch(url).then(r => r.json());
const maintenanceUrl = process.env.NEXT_PUBLIC_API_SANCTUARY_URL;
export const FALLBACK_MIN_BOOKING_DAYS = 28;

export const useSanctuary = () => {
  const { data, error } = useSWR(maintenanceUrl, fetcher);

  // todo: data transformations for consumers

  return {
    data,
    error,
  };
};
